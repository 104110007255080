<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Servicios</h4>
            <div class="small text-muted">Administración general de los servicios prestados</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">

            <b-tab title="General" :active="tabs.main" @click="setActiveTab('main')">          
              <b-row>
                <b-col :md="isProcessSalesFlow ? 3 : 5">               
                  <Widget :configWidget="configWidget" reference="list_services" :hasAccess="configWidget.elements.listServices" />                            
                </b-col>
                <b-col :md="isProcessSalesFlow ? 5 : 7">
                  <Widget :configWidget="configWidget" reference="list_next_services_expiration" :hasAccess="configWidget.elements.listNextServicesExpiration" />                            
                </b-col>
                <b-col md="4" v-if="isProcessSalesFlow">
                  <Widget :configWidget="configWidget" reference="list_services_customers_from_lead" :hasAccess="configWidget.elements.listServicesCustomersFromLead" />                            
                </b-col>            
              </b-row>
            </b-tab>

            <b-tab title="Control" :active="tabs.control" @click="setActiveTab('control')">
              <b-row>
                <b-col md="6">
                  <Widget :configWidget="configWidget" reference="list_services_customers_details_without_activity" :hasAccess="configWidget.elements.listServicesCustomersDetailsWithoutActivity" />
                </b-col>
                <b-col md="6">              
                  <Widget :configWidget="configWidget" reference="list_services_customers_activity_without_details" :hasAccess="configWidget.elements.listServicesCustomersActivityWithoutDetails" />                            
                </b-col>      
                <b-col md="12">
                  <Widget :configWidget="configWidget" reference="list_services_customers_details_diferent_activity" :hasAccess="configWidget.elements.listServicesCustomersDetailsDiferentActivity" />              
                </b-col>
              </b-row>          
            </b-tab>
            
          </b-tabs>
        </b-col>  
        <b-col md="3">
          <b-list-group class="mb-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCategory()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Categorías</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de las categorías de servicios
              </p>
            </b-list-group-item>
          
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openServices()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Servicios</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de servicios
              </p>
            </b-list-group-item>
          </b-list-group>    

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openServicesCustomers()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Servicio a Cliente</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Adiministración de servicios a clientes
              </p>
            </b-list-group-item>

            <b-list-group-item href="#" class="flex-column align-items-start" @click="openDisplayServicesCustomers()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Monitor de Servicios</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Visualiza todos los servicios a clientes
              </p>
            </b-list-group-item>            
          </b-list-group>               
        
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  //import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Storage from '@/handler/storageLocal'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.SERVICIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.SERVICIOS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            listServices: true, 
            listNextServicesExpiration: true, 
            listServicesCustomersFromLead: true,          
            listServicesCustomersDetailsWithoutActivity: true, 
            listServicesCustomersActivityWithoutDetails: true, 
          }
        },
        primaryColor: '',
        tabs: {
          main: true,
          control: false,          
        },
        modulesActive: []
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
        this.configWidget.elements.listServices = Helper.hasAccessWidget(this.configWidget, 'list_services')      
        this.configWidget.elements.listNextServicesExpiration = Helper.hasAccessWidget(this.configWidget, 'list_next_services_expiration')      
        this.configWidget.elements.listServicesCustomersFromLead = Helper.hasAccessWidget(this.configWidget, 'list_services_customers_from_lead')      
        this.configWidget.elements.listServicesCustomersDetailsWithoutActivity = Helper.hasAccessWidget(this.configWidget, 'list_services_customers_details_without_activity')      
        this.configWidget.elements.listServicesCustomersActivityWithoutDetails = Helper.hasAccessWidget(this.configWidget, 'list_services_customers_activity_without_details')      
        this.configWidget.elements.listServicesCustomersDetailsDiferentActivity = Helper.hasAccessWidget(this.configWidget, 'list_services_customers_details_diferent_activity')      
      /* Fin configuracion */
    },
    mounted() {
      this.modulesActive = Session.getSession().auth.user.permissions.modules
      this.setup()

      this.restoreStatusTabsStorage()
    }, 
    computed: {
      isProcessSalesFlow() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.FLUJO_VENTAS) {
            status = true
          }
        })
        return status
      },
    },
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      
      openServicesCustomers() {
        this.$router.push({ name: 'ServicesStaffCrudServicesCustomers' })
      },
      openDisplayServicesCustomers() {
        this.$router.push({ name: 'ServicesStaffDisplayServicesCustomers' })
      },
      openCategory() {
        this.$router.push({ name: 'ServicesStaffCrudCategory' })
      },
      openServices() {
        this.$router.push({ name: 'ServicesStaffCrudServices' })
      },
      
      //TABS
      disableAllTabs() {
        this.tabs.main = false
        this.tabs.control = false
      },
      setActiveTab(value) {
        this.disableAllTabs()

        switch (value) {
          case 'main':
            this.tabs.main = true
            break;

          case 'control':
            this.tabs.control = true
            break;
        
          default:
            this.tabs.main = true
            break;
        }

        this.saveStatusTabsStorage()
      },      
      
      // FILTROS SAVE
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_dashboard_services', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_dashboard_services')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_dashboard_services'))
        }         
      },
    }    
   
  }
</script>

<style>

</style>
